// extracted by mini-css-extract-plugin
export var arrowAnimation = "Banner-module--arrowAnimation--34316";
export var backgroundImg = "Banner-module--backgroundImg--d2311";
export var bannerCurve = "Banner-module--bannerCurve--fd4d4";
export var bannerHeading = "Banner-module--bannerHeading--daee5";
export var bannerText = "Banner-module--bannerText--a0e61";
export var btn = "Banner-module--btn--58c4f";
export var btne = "Banner-module--btne--bf0a1";
export var curve = "Banner-module--curve--7a5e1";
export var dedicated = "Banner-module--dedicated--97462";
export var dedicatedBanner = "Banner-module--dedicatedBanner--a093e";
export var dedicatedHeadingStatic = "Banner-module--dedicatedHeadingStatic--3fc10";
export var description = "Banner-module--description--dd6de";
export var descriptionText = "Banner-module--descriptionText--ba083";
export var headingIntro = "Banner-module--headingIntro--6a0eb";
export var headingText = "Banner-module--headingText--929d4";
export var innerDedicated = "Banner-module--innerDedicated--1cb4a";
export var mainTitle = "Banner-module--mainTitle--4485d";
export var newBtn = "Banner-module--newBtn--5f901";
export var paragraphAi = "Banner-module--paragraphAi--0960c";
export var sliderButtons = "Banner-module--sliderButtons--a951a";
export var subTitle = "Banner-module--subTitle--a971b";