// extracted by mini-css-extract-plugin
export var bannerImage = "Introsection-module--bannerImage--b70fe";
export var bannerImage2 = "Introsection-module--bannerImage2--819e8";
export var card = "Introsection-module--card--c760d";
export var cardTitle = "Introsection-module--cardTitle--847b1";
export var description = "Introsection-module--description--891d9";
export var descriptionText = "Introsection-module--descriptionText--25de1";
export var headingContainer = "Introsection-module--headingContainer--8a8c6";
export var headingIntro = "Introsection-module--headingIntro--c03cc";
export var highlight = "Introsection-module--highlight--9991f";
export var paragraphAi = "Introsection-module--paragraphAi--fdd66";
export var sectionContainer = "Introsection-module--sectionContainer--e66cd";
export var subTitle = "Introsection-module--subTitle--b6b72";