// extracted by mini-css-extract-plugin
export var bets = "EngagementModels-module--bets--73641";
export var binanceBets = "EngagementModels-module--binanceBets--49488";
export var card = "EngagementModels-module--card--eaa15";
export var colPad = "EngagementModels-module--colPad--15a8c";
export var description = "EngagementModels-module--description--4c612";
export var dpinEngHead = "EngagementModels-module--dpinEngHead--c949d";
export var dpinEngdes = "EngagementModels-module--dpinEngdes--107bd";
export var heading = "EngagementModels-module--heading--0dc5c";
export var headingText = "EngagementModels-module--headingText--3f220";
export var l0L1Bets = "EngagementModels-module--l0L1Bets--d57cb";
export var l0L1Card = "EngagementModels-module--l0L1Card--adf69";
export var laptopContainer = "EngagementModels-module--laptopContainer--ab1e5";
export var mainDes = "EngagementModels-module--mainDes--1d5a2";
export var rwaHead = "EngagementModels-module--rwaHead--1f616";
export var web3ResultBg = "EngagementModels-module--web3ResultBg--e2a31";