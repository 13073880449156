import { graphql } from "gatsby"
import React from "react"
import SEORevamp from "../../components/common/SEO_Revamp"
import Banner from "../../components/sight-sync/Banner"
import MainLayout from "../../layouts/MainLayout"
import Technologiesweuse from "../../components/king-of-jungle/Technologiesweuse"
import AIConsultingService from "../../components/sight-sync/Introsection"
import KeyFeatures from "../../components/sight-sync/EngagementModels"
import KeyChallengesSolution from "../../components/sight-sync/KeyChalangesSolution"
import FeatureCards from "../../components/sight-sync/FeatureCards"
import Featureimg from "../../components/sight-sync/FeatureImg"
import Results from "../../components/metaverseDog/Result"
import Conclusion from "../../components/sight-sync/Conclusion"
import ContactSales from "../../components/common/ContactSales"

const SightSync = ({ data }) => {
  const banner = data?.strapiPage?.sections[0]
  const technologies = data?.strapiPage?.sections[1]
  const consultingService = data?.strapiPage?.sections[2]
  const keyChalanges = data?.strapiPage?.sections[4]
  const fuatureImage1 = data?.strapiPage?.sections[5]
  const fuatureImage2 = data?.strapiPage?.sections[6]
  const results = data?.strapiPage?.sections[7]
  const fuatureImage3 = data?.strapiPage?.sections[8]
  const conclusion = data?.strapiPage?.sections[9]

  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <MainLayout bgChanged={false} schemas={bodySchema}>
      <Banner strapiData={banner} />
      <Technologiesweuse hideCurve={true} strapiData={technologies} />
      <AIConsultingService strapiData={consultingService} />
      <KeyFeatures strapiData={data} />
      <KeyChallengesSolution strapiData={keyChalanges} />
      <FeatureCards strapiData={fuatureImage1} />
      <FeatureCards strapiData={fuatureImage2} customStyle={true} />
      <Results strapiData={results} customBackground={true} customGrid={true} />
      <Featureimg strapiData={fuatureImage3} />
      <Conclusion strapiData={conclusion} />
      <ContactSales bgColor="#f8f8f8" />
    </MainLayout>
  )
}

export const query = graphql`
  query SightSync {
    strapiPage(slug: { eq: "sight-sync-ai" }) {
      sections {
        title
        subTitle
        subTitle2
        cards {
          title
          title2
          subTitle
          subTitle2
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          jsonData {
            InfrastructureCode {
              InfraServices
            }
            caseServices {
              invocom_caseStudy {
                skills
              }
            }
            devops_support {
              programs {
                Custom___add_ons
                First_line_support
                Out_of_hours_support
                _24_7_support
              }
            }
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      buttons {
        url
        title
      }
      seo {
        metaTitle
        metaDescription
        schemas {
          visibilityIn
          structuredData
        }
      }
    }
  }
`
export default SightSync

export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })
  return (
    <SEORevamp
      title={metaTitle}
      description={metaDescription}
      schemas={headSchema}
      image="https://invozone-backend.s3.amazonaws.com/software_product_development_services_b1fd581822.svg"
    />
  )
}
