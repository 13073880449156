import React from "react"
import Container from "react-bootstrap/Container"
import * as styles from "./KeyChalangesSolution.module.scss"
import connectingLine from "../../images/invocom/arrow.webp"

function KeyChallengesSolution({ strapiData }) {
  return (
    <Container>
      <div className={styles.keyChallengesContainer}>
        <h2
          className={styles.title}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <div className={styles.challengesGrid}>
          {strapiData?.cards?.map((item, index) => (
            <div key={index} className={styles.challengeRow}>
              <div className={styles.leftBox}>
                <div className={styles.iconWrapper}>
                  <div className={`${styles.icon} ${styles[item.icon]}`}>
                    <img
                      src={item?.image1[0]?.localFile?.publicURL}
                      alt={item?.title}
                    />
                  </div>
                </div>
                <div className={styles.titleWrapper}>
                  <h3>{item?.title}</h3>
                </div>
              </div>

              <div className={styles.connectingLine}>
                <img src={connectingLine} alt="connecting line" />
              </div>

              <div className={styles.rightBox}>
                <div className={styles.content}>
                  <div className={styles.challengeContent}>
                    <h4>Challenge</h4>
                    <p>{item.subTitle}</p>
                  </div>
                  <div className={styles.solutionContent}>
                    <h4>Solution</h4>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: item?.description?.description,
                      }}
                    ></p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
     { strapiData?.secImages && strapiData?.secImages?.length > 0 &&
      <div className={styles.soloImg}>
        <img
          src={strapiData?.secImages?.[0]?.localFile?.publicURL}
          alt="laptop 1"
        />
      </div>
      }
    </Container>
  )
}

export default KeyChallengesSolution
