import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Banner.module.scss"

const BannerSlider = ({ strapiData, customStyling = false }) => {
  return (
    <div className={styles.dedicatedBanner}>
      <div className={styles.bannerText}>
        <Container>
          <Row className={styles.headingText}>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={customStyling ? 3 : 4}
              className={styles.headingContainer}
            >
              <h1
                className={styles.headingIntro}
                dangerouslySetInnerHTML={{ __html: strapiData?.title }}
              />
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={customStyling ? 9 : 8}
              className={styles.headingContainer}
            >
              <p
                className={styles.paragraphAi}
                style={{ width: customStyling ? "100%" : "600px" }}
                dangerouslySetInnerHTML={{ __html: strapiData?.subTitle }}
              />
            </Col>
          </Row>
        </Container>
      </div>
      <Container className={styles.innerDedicated}>
        <div className={styles.dedicated}>
          <img
            src={
              strapiData.secImages.length > 0 ? strapiData.secImages[0].url : ""
            }
            alt="bg image"
            className={styles.backgroundImg}
          />
        </div>
      </Container>
      <img
        className={styles.bannerCurve}
        src="https://invozone-backend.s3.us-east-1.amazonaws.com/line_vector_c415196118.png"
        alt="curve"
      />
    </div>
  )
}

export default BannerSlider
