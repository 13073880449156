import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import * as styles from "./EngagementModels.module.scss"

function FeatureCards({ strapiData }) {
  console.log("🚀 ~ FeatureCards ~ strapiData:", strapiData)
  return (
    <>
      <Container>
        <div
          className={styles.laptopContainer}
          style={{
            background: "#fff",
            display: "flex",
            textAlign: "center",
            gap: "50px",
            flexDirection: "column",
            marginTop: "58px",
          }}
        >
          {strapiData?.title && (
            <h2
              className={styles.title}
              dangerouslySetInnerHTML={{ __html: strapiData?.title }}
            />
          )}
          <Row>
            <Col md={6} className="mb-2">
              <img src={strapiData?.secImages?.[1]?.url} alt="laptop" />
            </Col>
            <Col md={6} className="mb-2">
              <Row>
                <Col md={12} className="mb-2">
                  <img src={strapiData?.secImages?.[0]?.url} alt="laptop" />
                </Col>
                <Col md={12} className="mb-2">
                  <img src={strapiData?.secImages?.[2]?.url} alt="laptop" />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  )
}

export default FeatureCards
