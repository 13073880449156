// extracted by mini-css-extract-plugin
export var aiIcon = "KeyChalangesSolution-module--ai-icon--4c294";
export var challengeContent = "KeyChalangesSolution-module--challengeContent--bdf69";
export var challengeRow = "KeyChalangesSolution-module--challengeRow--92abe";
export var challengesGrid = "KeyChalangesSolution-module--challengesGrid--421de";
export var connectingLine = "KeyChalangesSolution-module--connectingLine--d0d6c";
export var content = "KeyChalangesSolution-module--content--2c8ea";
export var graphIcon = "KeyChalangesSolution-module--graph-icon--a2068";
export var icon = "KeyChalangesSolution-module--icon--64b25";
export var iconWrapper = "KeyChalangesSolution-module--iconWrapper--6ce83";
export var keyChallengesContainer = "KeyChalangesSolution-module--keyChallengesContainer--eb56c";
export var leftBox = "KeyChalangesSolution-module--leftBox--965a7";
export var rightBox = "KeyChalangesSolution-module--rightBox--5923e";
export var soloImg = "KeyChalangesSolution-module--soloImg--64108";
export var solutionContent = "KeyChalangesSolution-module--solutionContent--f27ad";
export var title = "KeyChalangesSolution-module--title--63e7b";
export var titleWrapper = "KeyChalangesSolution-module--titleWrapper--f19a8";
export var webglIcon = "KeyChalangesSolution-module--webgl-icon--15aed";