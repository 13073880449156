import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import * as styles from "./EngagementModels.module.scss"

function FeatureCards({ strapiData, customStyle = false }) {
  return (
    <>
      <Container>
        <div
          className={styles.laptopContainer}
          style={{
            background: "#fff",
            display: "flex",
            textAlign: "center",
            gap: "50px",
            flexDirection: "column",
            marginTop: customStyle ? "60px" : "-20px",
          }}
        >
          {strapiData?.title && (
            <h2
              style={{ fontSize: "48px", fontWeight: "700" }}
              dangerouslySetInnerHTML={{ __html: strapiData?.title }}
            />
          )}
          <Row>
            <Col md={12} className="mb-2">
              <img
                src={
                  strapiData?.secImages?.[0]?.url ||
                  "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1686557204_8ab4855614.webp"
                }
                alt="laptop"
              />
            </Col>
          </Row>
        </div>
      </Container>
    </>
  )
}

export default FeatureCards
