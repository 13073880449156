import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import * as styles from "./EngagementModels.module.scss"

function PerfectEngagement({
  marginTop,
  strapiData,
  customStyle = false,
  customStyle2 = false,
  headingWidth,
  backgroundColor = "transparent",
  cardBackgroud = "transparet",
  dpinEng,
  rwa,
  web3Result,
  binance,
  l0L1,
}) {
  const keyFeatures = strapiData?.strapiPage?.sections[3] || strapiData
  return (
    <>
      <div
        className={`${styles.bets} ${web3Result ? styles.web3ResultBg : ""} ${
          binance ? styles.binanceBets : ""
        } ${l0L1 ? styles.l0L1Bets : ""}`}
        style={{
          backgroundColor: backgroundColor,
          paddingTop: customStyle2 && 0,
          ...(customStyle && {
            paddingBottom: "90px",
            paddingTop: "30px",
            backgroundColor: backgroundColor,
          },
          marginTop && {
            marginTop: marginTop || "",
          }),
        }}
      >
        <Container>
          <h2
            className={`${styles.heading} ${
              dpinEng ? styles.dpinEngHead : ""
            } ${rwa ? styles.rwaHead : ""}`}
            style={{ width: headingWidth }}
            dangerouslySetInnerHTML={{ __html: keyFeatures?.title }}
          />
          <p
            className={`${styles.description} ${
              dpinEng ? styles.dpinEngdes : ""
            }`}
            dangerouslySetInnerHTML={{
              __html: keyFeatures?.subTitle,
            }}
          />
          <Row
            className="justify-content-center"
            style={{ gap: customStyle ? "16px" : 0 }}
          >
            {keyFeatures?.cards?.map((e, i) => (
              <Col
                key={i}
                md={customStyle ? 12 : 6}
                lg={customStyle ? 5 : 3}
                xl={customStyle ? 4 : 3}
                className={`${styles.colPad} d-flex gap-30`}
                style={{
                  ...(customStyle && {
                    maxWidth: "415px",
                    borderRadius: "16px",
                  }),
                }}
              >
                <div
                  className={`${styles.card} ${l0L1 ? styles.l0L1Card : ""}`}
                  style={{
                    backgroundColor: cardBackgroud,
                    ...(customStyle && {
                      backgroundColor: cardBackgroud,
                      borderRadius: "16px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      padding: "75px 48px",
                      height: "475px",
                    }),
                  }}
                >
                  <img
                    src={e?.image1[0]?.localFile?.publicURL}
                    alt="tick"
                    style={{
                      ...(customStyle && {
                        marginBottom: "24px",
                      }),
                    }}
                  />
                  <h3
                    className={styles.headingText}
                    style={{
                      ...(customStyle && {
                        fontSize: "32px",
                        textAlign: "center",
                      }),
                    }}
                    dangerouslySetInnerHTML={{
                      __html: e?.title,
                    }}
                  ></h3>

                  <p
                    style={{
                      ...(customStyle && {
                        color: "#808080",
                        textAlign: "center",
                      }),
                    }}
                    className={styles.mainDes}
                    dangerouslySetInnerHTML={{
                      __html: e?.subTitle,
                    }}
                  />
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  )
}

export default PerfectEngagement
