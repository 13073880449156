import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import * as styles from "./Result.module.scss"

const Results = ({
  strapiData,
  customBackground = false,
  customGrid = false,
}) => {
  return (
    <div
      className={styles.resultsSection}
      style={{ backgroundColor: customBackground ? "#F7F7F7" : "#FFF" }}
    >
      <Container>
        <div className={styles.heading}>
          <h2 dangerouslySetInnerHTML={{ __html: strapiData?.title }} />
        </div>
        <Row>
          {strapiData?.cards?.map((result, index) => (
            <Col
              md={customGrid ? 12 : 4}
              lg={4}
              key={index}
              style={{ padding: 0 }}
            >
              <div className={styles.resultCard}>
                <span className={styles.number}>
                  {String(index + 1).padStart(2, "0")}
                </span>
                <h3 className={styles.title}>{result.title}</h3>
                <p className={styles.description}>{result.subTitle}</p>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}

export default Results
