import React from "react"
import Container from "react-bootstrap/Container"
import * as styles from "./Conclusion.module.scss"

function Conclusion({ strapiData }) {
  console.log("🚀 ~ FeatureCards ~ strapiData:", strapiData)
  return (
    <>
      <Container>
        <div className={styles.heading}>
          {strapiData?.title && (
            <h2
              className={styles.title}
              dangerouslySetInnerHTML={{ __html: strapiData?.title }}
            />
          )}
          <p>{strapiData?.subTitle}</p>
        </div>
      </Container>
    </>
  )
}

export default Conclusion
